import React from "react"
import style from "../svg.module.scss"

const width = 24;
const height = 24;

const SvgDisclaimer = (props) => (
  <svg viewBox="0 0 24 24"
       height={height} width={width}
       className={style.svg + " " + props.className}
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       fillRule="evenodd" clipRule="evenodd"
       shapeRendering="geometricPrecision">

    <path d="M12 2.829l9.172 9.171-9.172 9.171-9.172-9.171 9.172-9.171zm0-2.829l-12 12 12 12 12-12-12-12zm-1 7h2v6h-2v-6zm1 10.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"/>

  </svg>
)

export default SvgDisclaimer;
