import React from "react"

import style from "./Highlights.module.scss";
import VBox from "../../../bosom/components/layout/boxes/VBox";
import HBox from "../../../bosom/components/layout/boxes/HBox";
import LinkButton from "../../../bosom/components/elements/buttons/link-button/LinkButton";
import IndexSelectSvg from "../assets/IndexSelectSvg";

const WhyAlsT = () => {

    return (
        <VBox className={style.parent}>

            <VBox className={style.content_wrapper + " " + style.sub_header}>
                <h3>Our Inspiration</h3>

                <blockquote cite={"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6065609/"}>
                    Despite the lack of a cure and the rapidly progressive nature of the disease, ALS is considered
                    a
                    “treatable disease” and rehabilitation is integral to optimal, comprehensive care. In addition
                    to
                    the other health care professions making up the health care team, physical therapy provides a
                    critical role in the overall management in individuals with ALS. Physical therapy that is
                    tailored
                    to the individual’s needs and goals and focused on addressing symptoms and maximizing function
                    and
                    participation enables people with ALS to live their lives to the fullest and with quality.
                    <aside>
                        <label>National Library of Medicine</label>
                        <a href="https://pubmed.ncbi.nlm.nih.gov/30890895/">Physical therapy for individuals with
                            amyotrophic lateral sclerosis: current insights</a>
                    </aside>
                </blockquote>
            </VBox>

            <HBox className={style.content_wrapper + " " + style.under_sub_header + " " + style.reverse}>

                <VBox className={style.box + " " + style.text_wrapper + " " + style.first}>

                    <p>Being personally affected by ALS, we set out on a journey to help our loved ones through this
                        difficult period. The quote above inspired and motivated us to undertake various forms of
                        self-directed occupational, speech, and physical treatments at home. As health centric
                        individuals, we utilized our knowledge to create a self directed process to fight the effects of
                        the illness. Consequently, we decided to share our experiences, solutions, and thoughts
                        in the form of this website with the hope that moving forward we can improve care and treatment for
                        individuals affected by ALS through a set of online solutions and self directed treatments.
                        It is out greatest hope that in at least a small way this site will help you and your family go
                        through a difficult journey ahead.</p>
                    <HBox className={style.link_wrapper}>
                        <LinkButton label={"About Us"} to={"about-us"}/>
                    </HBox>
                </VBox>
                <VBox className={style.box + " " + style.svg_wrapper}>
                    <IndexSelectSvg/>
                </VBox>

            </HBox>
        </VBox>
    )
}
export default WhyAlsT;
