import React, {Component} from "react"
import DefaultLayout from "../bosom/components/layout/default/DefaultLayout";
import Index from "../views/index";

class IndexPage extends Component {
    constructor(props) {
        super(props);
        const previewUrl = this.props.pageContext.data.exercises["wrist-raises"];
        const sampleSvgPath = previewUrl + "/exercise.svg";

        this.state = {
            pageContext: this.props.pageContext,
            previewUrl : previewUrl,
            svgPath : sampleSvgPath
        };
    };

/*    heartBeat(){
        this.timerUID = MiscUtil.callLater(this.heartBeat, 5000, this.timerUID);
        const analytics = firebase.analytics();
        analytics.logEvent('test-heart-beat', {
            content_type: 'heart-beat',
            content_id: this.timerUID,
            author : "index",
            items: [{ name: 'Dogs', beat : this.timerUID}, {something : "else?"}, {another : "object"}]
        });
    }*/

    render() {
        return (
            <DefaultLayout title={"Welcome"} context={this.props.pageContext}>
                <Index svgPath={this.state.svgPath} previewUrl={this.state.previewUrl}/>
            </DefaultLayout>
        )
    }
}

export default IndexPage;
