import React, { Component } from "react"
import { Link } from "gatsby"

import style from "./Header.module.scss";
import HBox from "../boxes/HBox"
import Logo from "../../elements/logo/Logo";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu_open : false
    };
    this.links = [
      {to:"/explore" , label :"Explore", cls:""},
      {to:"/about-us" , label :"About Us", cls:""},
      {to:"/als" , label :"ALS", cls:""},
      {to:"/health-notice" , label :"Health notice", cls:""},
      {to:"/login" , label :"Login", cls:style.action_btn + " " + style.login},
      {to:"/sign-up" , label :"Sign up", cls:style.action_btn + " " + style.join}
      /* {to:"/pricing" , label :"Pricing", cls:""}*/
    ]

    this.onMenuClick= this.onMenuClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  };

  onMenuClick(){
    const nextState = !this.state.menu_open;
    this.setState({ menu_open: nextState });
    if(nextState){
      document.addEventListener('mouseup', this.handleClickOutside);
    }
    else{
      document.removeEventListener('mouseup', this.handleClickOutside);
    }
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if(this.state.menu_open){
        this.onMenuClick();
      }
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  getNavLinks(){
    const n = this.links.length;
    const list = [];
    for(let i = 0; i < n; i++){
      const item = this.links[i];
      const key = item.to;
      const lb = item.label;
      const svg = item.svg ? item.svg : null;
      const cls = style.header_link + " " + item.cls;
      list.push(
        <Link to={key} key={"link_" + key + "_" + i} className={cls}>
          <h2>{lb}</h2>
          {svg}
        </Link>
      )
    }
    return list;
  }

  render() {
    const menu_bar_parent =  style.menu_bar_parent + (this.state.menu_open ? " " + style.open : "");
    const links_parent = style.links_parent + " " + (this.state.menu_open ? style.opened : "");
    const navLinks = this.getNavLinks();

    return (
      <HBox className={style.parent}>
        <Logo className={style.logo_wrapper}/>
        <HBox className={links_parent}>
          {navLinks}
        </HBox>
        <div role={"button"} tabIndex={0}
             className={menu_bar_parent}
             ref={this.setWrapperRef}
             onClick={this.onMenuClick}
             onKeyDown={this.onMenuClick}>
          <div className={style.bar}/>
          <div className={style.bar}/>
          <div className={style.bar}/>
        </div>

      </HBox>
    )
  }
}

export default Header;
