import React from "react"
import IndexHero from "./hero/IndexHero";
import SideKick from "./side-kick/SideKick";
import BetaDisclaimer from "../../bosom/components/layout/beta-disclaimer/BetaDisclaimer";
import NotASubstitute from "./highlights/NotASubstitute";
import WhyAlsT from "./highlights/WhyAlsT";
import WhatInside from "./highlights/WhatInside";
import SupportUs from "./highlights/SupportUs";

const Index = (props) => {

    return (
        <>
            <IndexHero svgPath={props.svgPath} previewUrl={props.previewUrl}/>
            <BetaDisclaimer/>
            <SideKick/>
            <WhyAlsT/>
            <WhatInside/>
            <NotASubstitute/>
            <SupportUs/>
        </>
    )
}

export default Index;
