import React from "react"

import style from "./IndexHero.module.scss";
import VBox from "../../../bosom/components/layout/boxes/VBox";
import LinkButton from "../../../bosom/components/elements/buttons/link-button/LinkButton";
import SVGViewer from "../svg-viewer/SVGViewer";
import Box from "../../../bosom/components/layout/boxes/Box";
import HBox from "../../../bosom/components/layout/boxes/HBox";

const IndexHero = ({svgPath, previewUrl}) => {

    return (
        <VBox className={style.parent}>

            <Box className={style.hero_wrapper}>
                <SVGViewer path={svgPath} className={style.svg_viewer}/>
                <VBox className={style.text_wrapper}>
                    <h1 className={style.heading}>Self directed treatment for people affected by <strong>ALS</strong>
                    </h1>
                    <p className={style.body_paragraph}>Care and tools designed to increase functional
                        independence, improve motor functions, and prolong effects of neuron degeneration for
                        individuals seeking self directed treatment online.</p>
                    <HBox className={style.link_wrapper}>
                        <LinkButton label={"Preview Exercise"} to={previewUrl} className={style.link_btn}/>
                    </HBox>
                    <p className={style.body_paragraph + " " + style.disclaimer}>ALSTherapy.io is a service provider for
                        self directed treatment, it does not offer medical advice, diagnosis, or cure, nor is it a substitute for professional care. Please consult
                        your doctor to learn if AlsTherapy.io is right for you.</p>
                </VBox>
            </Box>
        </VBox>
    )
}
export default IndexHero;
