import React from "react";
import style from "./Boxes.module.scss";
import PropTypes from "prop-types";

const VBox = function({ children, className, height, id }){
    const cn = style.box + ((className) ? " " + className : "");
    let customStyle = {};
    if(height){
        customStyle.height = height;
    }

    return (
        <div className={cn} style={customStyle}>
            {children}
        </div>
    )
};

VBox.propTypes = {
    children: PropTypes.node.isRequired,
};

export default VBox;
