import React, {Component} from "react"

import style from "./DefaultFooter.module.scss";
import HBox from "../../boxes/HBox"
import VBox from "../../boxes/VBox"
import FooterDisclaimer from "../disclaimer/FooterDisclaimer";
import SvgLogo from "../../../../../assets/svg/core/SvgLogo";

class DefaultFooter extends Component {

    render() {

        return (
            <VBox className={style.parent}>
                <SvgLogo className={style.svg_comp}/>
                <HBox className={style.footer_content_wrapper}>

                    <VBox className={style.logo_wrapper}>
                        <p>Copyright © {new Date().getFullYear()} AlsTherapy.io</p>
                        <p>All Rights Reserved.</p>
                    </VBox>
                </HBox>
            </VBox>
        )
    }
}

export default DefaultFooter;
