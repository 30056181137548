import React from "react"

import style from "./Highlights.module.scss";
import VBox from "../../../bosom/components/layout/boxes/VBox";
import HBox from "../../../bosom/components/layout/boxes/HBox";
import LinkButton from "../../../bosom/components/elements/buttons/link-button/LinkButton";
import IndexGiftSvg from "../assets/IndexGiftSvg";

const SupportUs = () => {

    return (
        <VBox className={style.parent + " " + style.support}>

            <HBox className={style.content_wrapper}>

                <VBox className={style.box + " " + style.svg_wrapper + " " + style.first}>
                    <IndexGiftSvg/>
                </VBox>

                <VBox className={style.box + " " + style.text_wrapper + " " + style.last}>
                    <h3>Want to help?</h3>
                    <p>The fight against ALS continues. With your support and help we are able to create more content
                        and tools for individuals affected by this illness. Please consider supporting us by sending a
                        donation. Please feel free to reach out to us if you would like to get in touch or get involved in our effort
                        and mission.</p>
                    <HBox className={style.link_wrapper}>
                        <LinkButton label={"Donate"} to={"terms"} className={style.link_btn}/>
                        <a href={"mailTo:contact@alstherapy.io"}>Contact Us</a>
                    </HBox>
                </VBox>


            </HBox>
        </VBox>
    )
}
export default SupportUs;
