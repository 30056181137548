import React from "react"

import style from "./SideKick.module.scss";
import VBox from "../../../bosom/components/layout/boxes/VBox"
import LinkButton from "../../../bosom/components/elements/buttons/link-button/LinkButton";

const SideKickItem = (props) => {
    const titleElm = (props.title) ?  <h3>{props.title}</h3> : null;
    return (
        <VBox className={style.side_kick_wrapper}>
            {props.svg}
            <VBox className={style.v_wrapper}>
                {titleElm}
                <p>{props.children}</p>
                <LinkButton label={props.label} to={props.toPath} className={style.link_btn}/>
            </VBox>
        </VBox>

    )
}

export default SideKickItem;
