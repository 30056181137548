import React from "react"

import style from "./Highlights.module.scss";
import VBox from "../../../bosom/components/layout/boxes/VBox";
import HBox from "../../../bosom/components/layout/boxes/HBox";
import IndexNotASubSvg from "../assets/IndexNotASubSvg";
import LinkButton from "../../../bosom/components/elements/buttons/link-button/LinkButton";

const NotASubstitute = () => {

    return (
        <VBox className={style.parent}>

            <HBox className={style.content_wrapper}>

                <VBox className={style.box + " " + style.text_wrapper + " " + style.first}>
                    <h3>Not a substitute</h3>
                    <p>
                        AlsTherapy.io is not a substitute to any professional care. It does not provide diagnoses, medical advice, or a cure. Instead it serves as an option for self-directed care
                        making it easier for individual around the globe to access specialized content in the comfort of their homes. Please consult
                        your doctor or a medical professional before using AlsTherapy.io to check if it can serve you.
                    </p>
                    <HBox className={style.link_wrapper}>
                        <LinkButton label={"Read Terms & Conditions of use"} to={"terms"}/>
                    </HBox>
                </VBox>
                <VBox className={style.box + " " + style.svg_wrapper+ " " + style.last}>
                    <IndexNotASubSvg/>
                </VBox>

            </HBox>

        </VBox>
    )
}
export default NotASubstitute;
