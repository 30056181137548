import React from "react"
import PropTypes from "prop-types"

import style from "./DefaultLayout.module.scss";
import SEO from "../../misc/SEO"
import VBox from "../boxes/VBox"
import HBox from "../boxes/HBox"
import DefaultFooter from "../footers/default/DefaultFooter"
import Header from "../headers/Header"

const DefaultLayout = ({children, title, context, type, entityKey}) => {
    return (
        <>
            <SEO title={title}/>

            <VBox className={style.parent}>
                <main className={style.main}>
                    {children}
                </main>
                <HBox className={style.header_parent}>
                    <Header/>
                </HBox>
            </VBox>

            <DefaultFooter/>
        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default DefaultLayout
