const MiscUtil = {
    getRandomNumber(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    },

    getRandomItemFromObject(obj, excludedKey) {
        const keys = Object.keys(obj);
        const key = this.getRandomItemFromList(keys);
        if (keys.length > 1 && excludedKey === key)
            return MiscUtil.getRandomItemFromObject(obj, excludedKey);
        return {key: key, value: obj[key]};
    },

    getRandomItemFromList(list) {
        const n = (list) ? list.length : 0;
        const idx = this.getRandomNumber(0, n);
        return list[idx] || null;
    },
    callLater(cb, delay, uid, name) {
        if (uid) {
            clearTimeout(uid);
        }
        return setTimeout(cb, delay || 0);
    },
    cloneAnything(something) {
        return JSON.parse(JSON.stringify(something));
    },
    createUUID(){
        let dt = new Date().getTime();
        const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c === 'x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    }
};

export default MiscUtil;
