import React from "react";
import style from "./Boxes.module.scss";
import PropTypes from "prop-types";

const HBox = function({ children, className, onClick }){
    const boxStyle = style.box + " " + style.horizontal + " " + className;

    let comp = <div className={boxStyle}>{children}</div>;
    if(onClick){
        comp = <div className={boxStyle}
                    role={'button'} tabIndex="0"
                    onClick={onClick} onKeyUp={onClick}>
            {children}
        </div>;
    }

    return (comp)
};

HBox.propTypes = {
    children: PropTypes.node.isRequired,
};

export default HBox;
