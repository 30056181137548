import React, {Component} from "react";
import {SvgLoader, SvgProxy} from 'react-svgmt';

import style from "./SVGViewer.module.scss";
import FetchUtil from "../../../bosom/utils/FetchUtil";
import MiscUtil from "../../../bosom/utils/MiscUtil";

class SVGViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.svgFetchComplete = this.svgFetchComplete.bind(this);
    }

    componentDidMount() {
        if(!this.state.svg){
            FetchUtil.fetchSVG(this.props.path, this.svgFetchComplete);
        }
    }

    svgFetchComplete(svgString, stepsInSvg) {
        this.setState({
            svgXML : svgString,
            stepCount : stepsInSvg
        })
    };

    getSvgElm() {
        if (this.state.svgXML) {// visibility: hidden;
            const steps = [];
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(this.state.svgXML,"text/xml");
            const svgElm = xmlDoc.querySelectorAll("svg g");
            const n = svgElm.length - 1;
            const stepIdx = MiscUtil.getRandomNumber(1, n - 1);
            const styleObj = {opacity : 0.1};
            //steps.push(<SvgProxy shape-rendering="geometricPrecision" key={"g"} selector="g" class={style.g} />);
            //steps.push(<SvgProxy shape-rendering="geometricPrecision" key={"table"} selector="#table" class={style.symbol} />);
            //steps.push(<SvgProxy shape-rendering="geometricPrecision" key={"towel"} selector="#towel" class={style.towel} />);
            //steps.push(<SvgProxy shape-rendering="geometricPrecision" key={"table"} selector="#table" class={style.table} />);

            //steps.push(<SvgProxy shape-rendering="geometricPrecision" key={"step"} selector="g.step" class={style.step} />);
            //steps.push(<SvgProxy shape-rendering="geometricPrecision" key={"step_first"} selector={"g#step_0"} class={style.step + " " + style.first_step} />);
            //steps.push(<SvgProxy shape-rendering="geometricPrecision" key={"step_last"} selector={"#step_" + n} class={style.step + " " + style.last_step} />);
            steps.push(<SvgProxy shape-rendering="geometricPrecision" key={"step_selected" + stepIdx} selector={"#step_" + stepIdx} class={style.selected_step} />);
            steps.push(<SvgProxy shape-rendering="geometricPrecision" key={"ascending"} selector="#ascending" class={style.arrow} />);
            steps.push(<SvgProxy shape-rendering="geometricPrecision" key={"descending"} selector="#descending" class={style.arrow} />);
            return <SvgLoader svgXML={this.state.svgXML} className={style.svg_elm}>{steps}</SvgLoader>;
        }
        return null;
    }

    render() {
        return (
            <div className={style.parent + " " + this.props.className}>
                {this.getSvgElm()}
            </div>
        )
    }
}

export default SVGViewer;
