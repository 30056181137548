import React, {Component} from "react"

import style from "./BetaDisclaimer.module.scss";
import VBox from "../boxes/VBox";
import HBox from "../boxes/HBox";
import SvgDisclaimer from "../../../../assets/svg/core/SvgDisclaimer";
import SvgClose from "../../../../assets/svg/core/SvgClose";
import DivButton from "../../elements/buttons/div-button/DivButton";

class BetaDisclaimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isClosed : false
        };

        this.onCloseClick = this.onCloseClick.bind(this);
    }

    onCloseClick(){
        this.setState({isClosed : true})
;    }

    getChildren(){
        if(this.state.isClosed){
            return null;
        }
        return <VBox className={style.parent}>

            <HBox className={style.content_wrapper}>
                <SvgDisclaimer className={style.svg_wrapper}/>
                <p>
                    <strong>We are under construction!</strong> Please note that due to active development this site is subjected to
                    continuous change and improvement. Please feel free to share your feedback with us or report a bug by sending an
                    email to: <a href={"mailTo:contact@alstherapy.io"}>contact@alstherapy.io</a>.
                </p>
                <DivButton onClick={this.onCloseClick}>
                    <SvgClose className={style.close_btn}/>
                </DivButton>

            </HBox>

        </VBox>
    }

    render() {
        return (
            this.getChildren()
        )
    }
}

export default BetaDisclaimer;
