import React from "react"

import style from "./Logo.module.scss";
import {Link} from "gatsby";
import SvgLogo from "../../../../assets/svg/core/SvgLogo";
import MiscUtil from "../../../utils/MiscUtil";
import VBox from "../../layout/boxes/VBox";
import HBox from "../../layout/boxes/HBox";

const Logo = ({className}) => {

    const randNum = MiscUtil.getRandomNumber(0,100);
    let randCls = style.g;
    if(randNum > 75)
        randCls = style.a;
    else if(randNum > 50)
        randCls = style.l;
    else if(randNum > 25)
        randCls = style.s;

    const clsName = style.parent + ((className) ? " " + className : "");

    return (
        <Link to="/" className={clsName}>
            <SvgLogo className={style.logo_svg + " " + randCls}/>
            <div className={style.post_it_wrapper}>
                <h2 className={style.char + " " + style.a + " " + style.special_font}>a</h2>
                <h2 className={style.char + " " + style.l + " " + style.special_font}>l</h2>
                <h2 className={style.char + " " + style.s + " " + style.special_font}>s</h2>
                <h2 className={style.char}>Therapy<small>.io</small></h2>
                <HBox className={style.beta_wrapper}>
                    <h4>B</h4>
                    <h4>E</h4>
                    <h4>T</h4>
                    <h4>A</h4>
                </HBox>
            </div>

        </Link>

    )
}

export default Logo;
