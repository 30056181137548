import React from "react"
import style from "./../../../assets/scss/_svgs.module.scss";

const width = '100%';
const height = '100%';


const IndexSelectSvg = (props) => (
    <svg viewBox="0 0 956.5802 472.46788"
         height={height} width={width}
         xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink"
         fillRule="evenodd" clipRule="evenodd"
         shapeRendering="geometricPrecision">

        <rect x="479.06523" y="508.47142" width="1.99915" height="138.03872"
              transform="translate(-392.50928 376.40486) rotate(-51.23093)" fill="#e6e6e6"/>
        <rect x="410.71925" y="577.49578" width="138.69111" height="2.00073"
              transform="translate(-379.03244 218.51137) rotate(-39.09657)" fill="#e6e6e6"/>
        <rect x="817.97404" y="250.89359" width="1.99989" height="316.24125"
              transform="translate(-138.18168 569.4692) rotate(-50.66761)" fill="#e6e6e6"/>
        <rect x="660.53598" y="408.51435" width="316.87601" height="1.99973"
              transform="translate(-195.19431 400.53658) rotate(-39.49025)" fill="#e6e6e6"/>
        <path
            d="M1014.2099,213.76606h-885a7.50271,7.50271,0,0,0-7.5,7.5v457a7.50272,7.50272,0,0,0,7.5,7.5h885a7.53035,7.53035,0,0,0,6.03-3.06,6.56732,6.56732,0,0,0,.95-1.72,7.23422,7.23422,0,0,0,.52-2.72v-457A7.50272,7.50272,0,0,0,1014.2099,213.76606Zm4.5,464.5a4.50681,4.50681,0,0,1-4.5,4.5h-885a4.50681,4.50681,0,0,1-4.5-4.5v-457a4.50677,4.50677,0,0,1,4.5-4.5h885a4.50677,4.50677,0,0,1,4.5,4.5Z"
            transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <rect x="3" y="38.33008" width="897" height="2" fill="#e6e6e6"/>
        <path
            d="M1051.65266,625.21642a215.891,215.891,0,0,1-10.08482,59.67912c-.14051.44836-.28775.89-.435,1.3384h-37.63576c.04015-.40154.08034-.84989.12049-1.3384,2.50949-28.84918,16.97756-204.43347-.32124-234.46045C1004.80876,452.871,1054.57036,533.54956,1051.65266,625.21642Z"
            transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path
            d="M1048.82867,684.89554c-.31455.44836-.64245.89671-.97705,1.3384h-28.23349c.21415-.38145.46175-.8298.7495-1.3384,4.66433-8.41853,18.46986-33.58708,31.285-59.67912,13.77212-28.03944,26.4066-57.14288,25.34255-67.67606C1077.32311,559.916,1086.85249,632.33666,1048.82867,684.89554Z"
            transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path
            d="M667.29015,398.23394h-238a7.00785,7.00785,0,0,1-7-7v-79a7.00786,7.00786,0,0,1,7-7h238a7.00787,7.00787,0,0,1,7,7v79A7.00786,7.00786,0,0,1,667.29015,398.23394Zm-238-91a5.00589,5.00589,0,0,0-5,5v79a5.00588,5.00588,0,0,0,5,5h238a5.00589,5.00589,0,0,0,5-5v-79a5.00589,5.00589,0,0,0-5-5Z"
            transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path
            d="M939.29015,625.23394h-238a7.00817,7.00817,0,0,1-7-7v-79a7.00818,7.00818,0,0,1,7-7h238a7.0085,7.0085,0,0,1,7,7v79A7.00849,7.00849,0,0,1,939.29015,625.23394Z"
            transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path
            d="M530.29015,511.23394h-101a7.00817,7.00817,0,0,1-7-7v-79a7.00786,7.00786,0,0,1,7-7h101a7.00787,7.00787,0,0,1,7,7v79A7.00818,7.00818,0,0,1,530.29015,511.23394Zm-101-91a5.00589,5.00589,0,0,0-5,5v79a5.00588,5.00588,0,0,0,5,5h101a5.00589,5.00589,0,0,0,5-5v-79a5.00589,5.00589,0,0,0-5-5Z"
            transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path
            d="M667.29015,511.23394h-101a7.00817,7.00817,0,0,1-7-7v-79a7.00786,7.00786,0,0,1,7-7h101a7.00787,7.00787,0,0,1,7,7v79A7.00818,7.00818,0,0,1,667.29015,511.23394Z"
            transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path
            d="M530.29015,624.23394h-101a7.00817,7.00817,0,0,1-7-7v-79a7.00818,7.00818,0,0,1,7-7h101a7.00818,7.00818,0,0,1,7,7v79A7.00818,7.00818,0,0,1,530.29015,624.23394Zm-101-91a5.00589,5.00589,0,0,0-5,5v79a5.00588,5.00588,0,0,0,5,5h101a5.00589,5.00589,0,0,0,5-5v-79a5.00589,5.00589,0,0,0-5-5Z"
            transform="translate(-121.7099 -213.76606)" fill="#3f3d56"/>
        <path
            d="M667.29015,624.23394h-101a7.00817,7.00817,0,0,1-7-7v-79a7.00818,7.00818,0,0,1,7-7h101a7.00818,7.00818,0,0,1,7,7v79A7.00818,7.00818,0,0,1,667.29015,624.23394Zm-101-91a5.00589,5.00589,0,0,0-5,5v79a5.00588,5.00588,0,0,0,5,5h101a5.00589,5.00589,0,0,0,5-5v-79a5.00589,5.00589,0,0,0-5-5Z"
            transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path
            d="M938.29015,512.23394h-238a7.00817,7.00817,0,0,1-7-7v-193a7.00786,7.00786,0,0,1,7-7h238a7.00818,7.00818,0,0,1,7,7v193A7.00849,7.00849,0,0,1,938.29015,512.23394Zm-238-205a5.00589,5.00589,0,0,0-5,5v193a5.00588,5.00588,0,0,0,5,5h238a5.00589,5.00589,0,0,0,5-5v-193a5.00589,5.00589,0,0,0-5-5Z"
            transform="translate(-121.7099 -213.76606)" fill="#3f3d56"/>
        <path
            d="M977.29015,547.23394h-238a7.00848,7.00848,0,0,1-7-7v-193a7.00818,7.00818,0,0,1,7-7h238a7.00818,7.00818,0,0,1,7,7v193A7.00849,7.00849,0,0,1,977.29015,547.23394Z"
            transform="translate(-121.7099 -213.76606)" className={style.a_fill} />
        <path
            d="M885.44323,499.73384H763.93778a2.47766,2.47766,0,0,1-.45406-.036l57.46648-99.54021a4.02365,4.02365,0,0,1,7.00406,0l38.56722,66.79921,1.84768,3.19635Z"
            transform="translate(-121.7099 -213.76606)" fill="#fff"/>
        <polygon
            points="763.733 285.968 722.248 285.968 742.613 256.387 744.079 254.256 744.812 253.191 746.66 256.387 763.733 285.968"
            opacity="0.2"/>
        <path
            d="M953.09658,499.73384H848.00434l20.36482-29.58065,1.46553-2.1309L896.37187,429.473a5.36965,5.36965,0,0,1,7.97059-.47651,4.796,4.796,0,0,1,.38215.47651Z"
            transform="translate(-121.7099 -213.76606)" fill="#fff"/>
        <circle cx="752.17993" cy="191.96778" r="18" fill="#fff"/>
        <rect x="623.58025" y="140.46788" width="22" height="22" fill="#3f3d56"/>
        <path
            d="M558.29015,646.23394h-101a7.00817,7.00817,0,0,1-7-7v-79a7.00818,7.00818,0,0,1,7-7h101a7.00818,7.00818,0,0,1,7,7v79A7.00818,7.00818,0,0,1,558.29015,646.23394Z"
            transform="translate(-121.7099 -213.76606)" className={style.a_fill} />
        <path
            d="M519.1213,623.10305h-50.705a1.03438,1.03438,0,0,1-.18948-.015L492.208,581.54928a1.6791,1.6791,0,0,1,2.92284,0L511.22513,609.425l.77105,1.33386Z"
            transform="translate(-121.7099 -213.76606)" fill="#fff"/>
        <polygon
            points="397.411 409.337 380.1 409.337 388.598 396.993 389.21 396.104 389.515 395.659 390.286 396.993 397.411 409.337"
            opacity="0.2"/>
        <path
            d="M547.35347,623.10305H503.49782l8.49836-12.34419.61158-.88924,11.07413-16.08684a2.24078,2.24078,0,0,1,3.32617-.19886,2.00131,2.00131,0,0,1,.15948.19886Z"
            transform="translate(-121.7099 -213.76606)" fill="#fff"/>
        <circle cx="392.59009" cy="370.1102" r="7.51151" fill="#fff"/>
        <rect x="338.58025" y="347.46788" width="16" height="16" fill="#3f3d56"/>
        <path id="f57cf1cd-83f7-4fd3-a8d1-d71a660a6415" data-name="Path 40"
              d="M221.90813,356.61926a5.94683,5.94683,0,0,0,0,11.892H328.99386a5.94683,5.94683,0,0,0,.19525-11.892q-.09762-.00165-.19525,0Z"
              transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path id="aee25563-7463-4829-8b3c-d6945ce6dfe7" data-name="Path 40"
              d="M221.90813,387.61926a5.94683,5.94683,0,0,0,0,11.892H328.99386a5.94683,5.94683,0,0,0,.19525-11.892q-.09762-.00165-.19525,0Z"
              transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path id="aefa15f9-40e4-4075-8c80-8ab2e97e1ce3" data-name="Path 40"
              d="M221.90813,418.61926a5.94683,5.94683,0,0,0,0,11.892H328.99386a5.94683,5.94683,0,0,0,.19525-11.892q-.09762-.00165-.19525,0Z"
              transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path id="a405b459-42e8-4eb5-9988-b9086189387e" data-name="Path 40"
              d="M221.90813,449.61926a5.94683,5.94683,0,0,0,0,11.892H328.99386a5.94683,5.94683,0,0,0,.19525-11.892q-.09762-.00165-.19525,0Z"
              transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path id="b32f5175-a272-4d51-9048-0542c511c928" data-name="Path 40"
              d="M221.90813,480.61926a5.94683,5.94683,0,0,0,0,11.892H328.99386a5.94683,5.94683,0,0,0,.19525-11.892q-.09762-.00165-.19525,0Z"
              transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path id="b3f78383-4856-4bdc-97c4-b8cb03fde67d" data-name="Path 40"
              d="M221.90813,511.61926a5.94683,5.94683,0,0,0,0,11.892H328.99386a5.94683,5.94683,0,0,0,.19525-11.892q-.09762-.00165-.19525,0Z"
              transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path
            d="M183.6366,373.59688a11,11,0,1,1,11-11A11.01245,11.01245,0,0,1,183.6366,373.59688Zm0-20a9,9,0,1,0,9,9A9.01016,9.01016,0,0,0,183.6366,353.59688Z"
            transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path
            d="M183.6366,404.59688a11,11,0,1,1,11-11A11.01245,11.01245,0,0,1,183.6366,404.59688Zm0-20a9,9,0,1,0,9,9A9.01016,9.01016,0,0,0,183.6366,384.59688Z"
            transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path
            d="M183.6366,435.56563a11,11,0,1,1,11-11A11.01245,11.01245,0,0,1,183.6366,435.56563Zm0-20a9,9,0,1,0,9,9A9.01016,9.01016,0,0,0,183.6366,415.56563Z"
            transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path
            d="M183.6366,466.56563a11,11,0,1,1,11-11A11.01245,11.01245,0,0,1,183.6366,466.56563Zm0-20a9,9,0,1,0,9,9A9.01016,9.01016,0,0,0,183.6366,446.56563Z"
            transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path
            d="M183.6366,497.56563a11,11,0,1,1,11-11A11.01245,11.01245,0,0,1,183.6366,497.56563Zm0-20a9,9,0,1,0,9,9A9.01016,9.01016,0,0,0,183.6366,477.56563Z"
            transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <path
            d="M183.6366,528.56563a11,11,0,1,1,11-11A11.01245,11.01245,0,0,1,183.6366,528.56563Zm0-20a9,9,0,1,0,9,9A9.01016,9.01016,0,0,0,183.6366,508.56563Z"
            transform="translate(-121.7099 -213.76606)" fill="#e6e6e6"/>
        <circle cx="61.9267" cy="148.83082" r="5" className={style.a_fill} />
        <circle cx="61.9267" cy="210.83082" r="5" className={style.a_fill} />
        <circle cx="61.9267" cy="241.83082" r="5" className={style.a_fill} />
        <path
            d="M629.27781,480.34049l1.62056,12.15421a8.53072,8.53072,0,0,0,10.97829,7.02183h0a8.53074,8.53074,0,0,0,4.52229-12.96029l-7.27178-10.648-14.2816-37.92011,14.77406-19.20629-17.23641-7.387-14.2816,25.11592Z"
            transform="translate(-121.7099 -213.76606)" fill="#ffb8b8"/>
        <path
            d="M618.44349,412.37978l24.62345,9.35691,6.01167-16.6991a9.991,9.991,0,0,0-4.47474-12.0766h0a9.99106,9.99106,0,0,0-12.75916,2.491Z"
            transform="translate(-121.7099 -213.76606)" className={style.a_fill} />
        <path
            d="M601.20708,645.81005l32.25574.78607,18.961-123.9033c17.98786,32.76373,32.254,76.42815,44.81467,125.08711l28.07073-6.4021c-7.82107-51.78336-22.15437-98.45659-43.82974-139.36871l.98494-28.07073-44.81467-6.89456a20.33193,20.33193,0,0,0-3.59444,2.15583C608.73294,488.05676,605.45521,572.97153,601.20708,645.81005Z"
            transform="translate(-121.7099 -213.76606)" fill="#2f2e41"/>
        <path
            d="M704.62555,686.1925l10.781-1.34762-1.42407-12.934c4.3734,8.79906,9.95976,12.64081,16.67311,11.87648a8.40756,8.40756,0,0,0,6.90434-5.78367h0a8.57533,8.57533,0,0,0,.24044-.87215,5.29047,5.29047,0,0,0-3.43529-5.92687c-8.05329-3.20957-10.25235-17.81288-12.01063-33.27414l-13.29666,2.46234.49247,11.32679C701.96783,662.70092,701.74793,673.68808,704.62555,686.1925Z"
            transform="translate(-121.7099 -213.76606)" fill="#2f2e41"/>
        <path
            d="M608.10164,686.1925l10.781-1.34762-1.42407-12.934c4.3734,8.79906,9.95976,12.64081,16.67311,11.87648a8.40757,8.40757,0,0,0,6.90434-5.78367h0a8.57533,8.57533,0,0,0,.24044-.87215,5.29047,5.29047,0,0,0-3.43529-5.92687c-8.05329-3.20957-10.25235-17.81288-12.01063-33.27414l-13.29666,2.46234.49247,11.32679C605.44392,662.70092,605.224,673.68808,608.10164,686.1925Z"
            transform="translate(-121.7099 -213.76606)" fill="#2f2e41"/>
        <circle cx="545.79432" cy="157.16325" r="17.4676" fill="#ffb8b8"/>
        <path
            d="M633.21756,404.50028l23.146-3.93976,11.81925-19.69875-16.74394-10.83432C648.16368,381.35852,641.7011,392.87167,633.21756,404.50028Z"
            transform="translate(-121.7099 -213.76606)" fill="#ffb8b8"/>
        <path
            d="M635.3138,468.749,681.972,479.848c3.59427-29.2063,2.37818-54.75579-6.16024-74.73891A21.77528,21.77528,0,0,0,661.2883,392.681h0l-19.69859-.98494h0a22.68,22.68,0,0,0-12.49716,24.11934C636.56938,435.52374,641.71313,454.34088,635.3138,468.749Z"
            transform="translate(-121.7099 -213.76606)" className={style.l_fill} />
        <path
            d="M720.51316,460.4656l10.88792,5.63952a8.53073,8.53073,0,0,0,12.04685-4.97016v0A8.53073,8.53073,0,0,0,735.42091,450l-12.89329-.14556-39.25794-10.03556-7.26807-23.11558-15.93355,9.8886,12.39262,26.09973Z"
            transform="translate(-121.7099 -213.76606)" fill="#ffb8b8"/>
        <path
            d="M658.61665,430.38467l21.78223-14.81217-10.23675-14.49858a9.991,9.991,0,0,0-12.46049-3.25637h0a9.991,9.991,0,0,0-5.27312,11.88256Z"
            transform="translate(-121.7099 -213.76606)" className={style.l_fill} />
        <path
            d="M663.5044,370.76615c6.69882-.95233,12.40412-2.67666,19.10294-3.629,1.65176-.23482,3.54807-.63485,4.31574-2.11611.70744-1.36506.10964-3.02989-.62984-4.37785a22.36731,22.36731,0,0,0-39.03484-.33182c-1.76688,3.0948-2.80577,6.62715-5.0472,9.39764a33.32292,33.32292,0,0,1-4.96838,4.52716,68.3558,68.3558,0,0,0-24.244,44.7506,28.63142,28.63142,0,0,0,.24853,9.58966,13.39457,13.39457,0,0,0,4.993,7.99673c3.88108,2.7484,9.26551,2.51851,13.63465.64046,8.36779-3.59683,13.59132-12.42352,15.10569-21.40481s-.12457-18.17812-2.15162-27.05776c-.73981-3.24078-1.52915-6.65192-.58065-9.83788,1.31757-4.42565,7.42857,2.62013,11.37642.225,1.64694-.9992,1.58525-11.86137,3.50493-11.70139s3.77067,2.14951,3.02445,3.92545Z"
            transform="translate(-121.7099 -213.76606)" fill="#2f2e41"/>
        <path
            d="M645.66057,378.8159a2.21659,2.21659,0,0,0,.69158-3.05677l-2.82208-4.47189a2.21615,2.21615,0,0,0-3.74834,2.36544l2.82207,4.47189a2.2189,2.2189,0,0,0,3.05726.69133Z"
            transform="translate(-121.7099 -213.76606)" className={style.l_fill} />
        <circle cx="27.7901" cy="21.73394" r="8.5" fill="#e6e6e6"/>
        <circle cx="52.4401" cy="21.73394" r="8.5" fill="#e6e6e6"/>
        <circle cx="77.0901" cy="21.73394" r="8.5" fill="#e6e6e6"/>
    </svg>
)

export default IndexSelectSvg;
