import React from "react"

import style from "./SideKick.module.scss";
import VBox from "../../../bosom/components/layout/boxes/VBox"
import HBox from "../../../bosom/components/layout/boxes/HBox";
import SideKickItem from "./SideKickItem";
import IndexExploreSvg from "../assets/IndexExploreSvg";
import IndexSessionsSvg from "../assets/IndexSessionsSvg";
import IndexQuestionsSvg from "../assets/IndexQuestionsSvg";

const SideKick = ({data}) => {

    return (
        <VBox className={style.parent}>
            <p className={style.call_out_p}>We are on the mission to create and share digital solutions for individuals
                and care givers affected by ALS illness.</p>
            <HBox className={style.content_wrapper}>

                <SideKickItem svg={<IndexExploreSvg/>}
                              title={"Exercises Library"}
                              label={"Explore exercises"}
                              toPath={"/exercises"}>
                    Browse through specialized exercises and movements that focus on specific areas of the body. Each
                    comes with a detailed description to help ensure safety and proper form.
                </SideKickItem>

                <SideKickItem svg={<IndexSessionsSvg/>}
                              title={"ALS Sessions"}
                              label={"Explore sessions"}
                              toPath={"/sessions"}>
                    Sessions arrange exercises and activities in carefully vetted sequence maximizing your experience,
                    saving you the effort of finding out which ones fit well together, and in what order.
                </SideKickItem>

                <SideKickItem svg={<IndexQuestionsSvg/>}
                              title={"About ALS"}
                              label={"Learn about ALS"}
                              toPath={"/als"}>
                    Our content has been specifically created for persons with ASL and caregivers. ALS is a progressive
                    nervous system disease that affects nerve cells in the brain and spinal cord, causing loss of muscle
                    control.
                </SideKickItem>

            </HBox>

        </VBox>

    )
}

export default SideKick;
