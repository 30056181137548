import React from "react"

import style from "./Highlights.module.scss";
import VBox from "../../../bosom/components/layout/boxes/VBox";
import HBox from "../../../bosom/components/layout/boxes/HBox";
import IndexPeekInsideSvg from "../assets/IndexPeekInsideSvg";
import LinkButton from "../../../bosom/components/elements/buttons/link-button/LinkButton";

const WhatInside = () => {

    return (
        <VBox className={style.parent}>

            <HBox className={style.content_wrapper}>
                <VBox className={style.box + " " + style.svg_wrapper + " " + style.first}>
                    <IndexPeekInsideSvg/>
                </VBox>
                <VBox className={style.box + " " + style.text_wrapper + " " + style.last}>
                    <h3>What's Inside?</h3>
                    <p>Our mission is to continue to create tools & features tailored for persons with ALS. At present, we offer:</p>
                    <ul>
                        <li><strong>29</strong> physical therapies</li>
                        <li><strong>84</strong> occupational therapies</li>
                        <li><strong>12</strong> speech therapies</li>
                        <li><strong>94</strong> stretching techniques</li>
                        <li><strong>20</strong> targeted sessions</li>
                        <li><strong>7</strong> specialized tracks</li>
                    </ul>
                    <HBox className={style.link_wrapper}>
                        <LinkButton label={"Explore"} to={"explore"}/>
                    </HBox>
                </VBox>


            </HBox>
        </VBox>
    )
}
export default WhatInside;
